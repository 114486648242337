// routes
import { paths } from 'src/routes/paths';

// ----------------------------------------------------------------------

// config
export const IS_ONLINE = false;
export const IS_HAS_MENU = true;

const PORT = localStorage.getItem('REACT_APP_LOCAL_HOST_API');

const IMAGE_PORT = localStorage.getItem('REACT_APP_LOCAL_IMAGE_HOST_API');

export const HOST_API = PORT || process.env.REACT_APP_LOCAL_HOST_API;

export const IMAGE_HOST_API = IMAGE_PORT || process.env.REACT_APP_LOCAL_IMAGE_HOST_API;

// IFAME
export const CHAT_AI = process.env.REACT_APP_CHAT_AI;
// AI
export const GEMINI_API_KEY = process.env.REACT_APP_GEMINI_API_KEY;
export const GPT_API_KEY = process.env.REACT_APP_GPT_API_KEY;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = paths.home.root; // as '/home'
